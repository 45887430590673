<template>
  <div class="main" data-title="战败审核人配置" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">战败审核人配置</h2>
        <div class="page-header-desc">配置战败、失控客户审核人</div>
      </div>
      <div class="head-search"></div>
      <div class="head-tabs">
        <div @click="$router.push({name: 'database-config'})" class="tab">基础数据配置</div>
        <div @click="$router.push({name: 'database-custom'})" class="tab">自定义字段</div>
        <div @click="$router.push({name: 'database-reviewer'})" class="tab curr">战败审核人</div>
        <div @click="$router.push({name: 'database-kpi'})" class="tab">销售目标设置</div>
      </div>
    </div>

    <el-card class="main-content" shadow="hover">
      <div class="menu-box">
        <div class="left-menu">
          <router-link
            :class="key == item.key ? 'curr' : ''"
            :key="index"
            :to="{query: {key: item.key}}"
            @click.native="init"
            class="left-menu-item"
            v-for="(item, index) in keyArr"
          >{{item.name}}</router-link>
        </div>
        <div class="right-main">
          <div class="head">
            <div class="head-title">战败审核人</div>
            <div class="head-btns">
              <el-button
                @click="add"
                class="btn"
                icon="el-icon-edit"
                type="primary"
                v-has="'cfgdata-create'"
              >新建</el-button>
            </div>
          </div>

          <div class="body">
            <div class="table-wrapper">
              <el-table :data="listData" class="table" style="width: 100%">
                <el-table-column label="审核人">
                  <template slot-scope="scope">{{$store.state.userList[scope.row.f1][0]}}</template>
                </el-table-column>
                <el-table-column label="备注" prop="memo"></el-table-column>
                <el-table-column fixed="right" label="操作" width="100px">
                  <template slot-scope="scope">
                    <el-button
                      @click="deleteItem(scope.$index, scope.row)"
                      class="btn-delete no-bg"
                      size="small"
                      type="text"
                      v-has="'cfgdata-remove'"
                    >移除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <!-- 数量编辑 -->
    <el-dialog :visible.sync="visible" title="添加">
      <el-form
        :model="ruleForm"
        :rules="rules"
        class="ruleForm"
        label-width="100px"
        ref="ruleForm"
        status-icon
      >
        <el-form-item label="审核人:" prop="f1">
          <el-select filterable placeholder="请选择" v-model="ruleForm.f1">
            <el-option
              :key="key"
              :label="item[0]"
              :value="key"
              v-for="(item, key) in $store.state.userList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input :rows="2" placeholder="请输入" type="textarea" v-model="ruleForm.memo"></el-input>
        </el-form-item>
      </el-form>

      <span class="dialog-footer" slot="footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button @click="submitForm('ruleForm')" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keyArr: [
        {
          name: '战败审核人',
          key: 'reviewer'
        }
      ],
      key: 'reviewer',
      visible: false, //控制编辑对话框显示与隐藏
      ruleForm: {
        key: 'reviewer',
        f1: '',
        readonly: 2,
        memo: ''
      },
      rules: {
        f1: [{ required: true, message: '必需填写', trigger: 'change' }]
      },
      listData: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      //高亮菜单
      this.getListData()
    },

    //获取数据列表
    getListData() {
      this.$axios
        .post('/cfgdata/lists', {
          key: this.key
        })
        .then(res => {
          if (res.data.code == 200) {
            if (JSON.stringify(res.data.data) === '{}') {
              this.listData = []
            } else {
              this.listData = res.data.data[0].list
            }
          }
        })
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post('/cfgdata/create', this.ruleForm).then(res => {
            if (res.data.code == '200') {
              this.getListData()
              this.$message.success(res.data.msg)
              this.visible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      // Object.assign(this.ruleForm, this.$options.data().ruleForm)
      this.visible = false
    },
    deleteItem(index, item) {
      this.$confirm(
        '此操作将 ' +
          this.$store.state.userList[item.f1][0] +
          '从战败审核人中移除 , 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$axios
            .post('/cfgdata/remove', {
              sys_id: item.sys_id
            })
            .then(res => {
              if (res.data.code == 200) {
                this.getListData()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {})
    },
    add() {
      this.ruleForm.sys_id = ''
      this.ruleForm.name = ''
      this.ruleForm.order = '1'
      this.ruleForm.memo = ''

      this.visible = true
    }
  }
}
</script>
<style lang="less" scoped>
.main-content {
  /deep/ .el-card__body {
    padding-left: 0;
    padding-right: 0;
  }
}
.menu-box {
  display: flex;
  .left-menu {
    width: 224px;
  }
  .right-main {
    margin: 8px 40px;
    flex: 1;
    .head {
      display: flex;
      margin-bottom: 24px;
      .head-title {
        font-weight: 500;
        font-size: 20px;
        flex: 1;
      }
      .head-btns {
        display: flex;
        align-items: center;
        i.el-icon-check {
          font-size: 20px;
          color: #409eff;
        }
      }
    }
  }
}

.ruleForm {
  padding: 0 80px;
}

.left-menu {
  border-right: 1px solid #e8e8e8;
  .left-menu-item {
    cursor: pointer;
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 8px;
    &:hover {
      color: #409eff;
    }
    &.curr {
      background: #e6f7ff;
      font-weight: 500;
      color: #409eff;
      border-right: 3px #409eff solid;
    }
  }
}
</style>
import { render, staticRenderFns } from "./Reviewer.vue?vue&type=template&id=f65c06d6&scoped=true&"
import script from "./Reviewer.vue?vue&type=script&lang=js&"
export * from "./Reviewer.vue?vue&type=script&lang=js&"
import style0 from "./Reviewer.vue?vue&type=style&index=0&id=f65c06d6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f65c06d6",
  null
  
)

export default component.exports